<template>
  <div class="container contents help-contents">
    <h2 class="text-center font-weight-bold">이용약관</h2>
    <h3 class="title">제1장 총칙</h3>
    <br>
    <h4 class="title-left title">제 1조 (목적)</h4>
    <ol class="help">
      이 약관은 (주)마야크루 (이하 '회사'라 함)가 어플리케이션과 모바일 및 PC 포함 이와 관련된 웹사이트를 통해 제공하는 슈퍼멤버스(www.supermembers.co.kr), 슈퍼차트(www.superchart.com) 서비스 및 관련 제반 서비스의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
    </ol>

    <h4 class="title-left title">제 2조 (정의)</h4>
    <ol class="help">
      <li>'사이트'란 슈퍼멤버스(www.supermembers.co.kr, ceo.supermembers.co.kr), 슈퍼차트(www.superchart.com)를 말합니다.</li>
      <li>‘어플리케이션’이란 스마트폰 및 이동통신기기를 통해 제공되는 슈퍼멤버스, 슈퍼차트 서비스를 말합니다.</li>
      <li>'회원'이란 슈퍼멤버스, 슈퍼차트에 회원가입을 하여 회사가 제공하는 서비스를 이용하는 고객을 말합니다.</li>
      <li>'아이디(ID)'란 회원 식별과 서비스 이용을 위하여 회원이 선정하고 회사가 승인한 문자와 숫자의 조합을 말합니다.</li>
      <li>'비밀번호'란 회원의 정보 보호와 안전한 서비스 이용을 위하여 회원이 선정한 문자와 숫자의 조합을 말합니다.</li>
      <li>‘광고주’란 마케팅 또는 콘텐츠 제휴 등을 목적으로 회사와 계약 관계에 있는 개인 또는 단체를 말합니다.</li>
      <li>‘광고비’란 광고주가 슈퍼멤버스, 슈퍼차트 서비스를 통한 마케팅 또는 콘텐츠 제휴 등의 대가로 회사에 지급하는 이용금액을 말합니다.</li>
      <li>‘광고 제안금액’이란 , 슈퍼차트 서비스를 통해 광고주가 회원에게 광고의 댓가로 제안하는 회사의 수수료가 포함된 비용을 말합니다.</li>
      <li>‘멤버십서비스’란 회사가 마케팅 등을 목적으로 광고주 혹은 회원들이 저작물을 등록하거나 그 외의 방법으로 참여 할 수 있게 제공하는 서비스를 말합니다.</li>
      <li>'슈퍼멤버스 서비스'(이하 '서비스')란 어플리케이션 및 사이트에 게시된 멤버십서비스에 참여하여 광고주가 제시한 조건을 만족하는 회원에게 광고주 및 회사가 제공하는 혜택 및 이와 관련된 제반 서비스를 말합니다.</li>
      <li>‘슈퍼차트 서비스’란 어플리케이션 및 사이트에 게시된 멤버십서비스에 참여하여 광고주가 제시한 조건을 만족하는 회원에게 광고주 및 회사가 제공하는 포인트 및 이와 관련된 제반 서비스를 말합니다.</li>
      <li>'멀티미디어'란 부호ᆞ문자ᆞ음성ᆞ음향ᆞ화상ᆞ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.</li>
      <li>'게시물'이란 회원이 서비스를 통해 블로그, 유튜브, 인스타그램 또는 소셜 미디어 등 온라인 전반에 작성 및 등록한 멀티미디어를 말합니다.</li>
      <li>'게시물 등록'이란 회원이 게시물의 URL 또는 그에 준하는 연결 고리를 회사에서 제공하는 방법으로 멤버십서비스에 참여하는 것을 말합니다 .</li>
      <li>'콘텐츠'란 회원이 멤버십서비스 이용 후 등록한 게시물 또는 회사가 복사한 게시물, 게시물의 URL을 말합니다.</li>
      <li>'멤버십'이란 회사가 정한 기준으로 멤버십서비스에 참여한 회원에게 부여하는 등급을 말합니다.</li>
      <li>'스폰서 배너'란 게시물에 멤버십서비스 참여를 표시하기 위해 회사가 제공하는 HTML 코드 또는 이미지 등을 말합니다.</li>
      <li>‘포인트’란 회사 및 광고주가 회원에게 제공하는 현금화 할 수 있는 무형의자산을 말합니다.</li>
      <li>‘충전금액’이란 광고주가 슈퍼차트 서비스를 통해 회원에게 광고 제안금액으로 지불하기 위한 충전금을 말합니다.</li>
    </ol>

    <h4 class="title-left title">제 3조 (이용약관 효력 및 적용과 변경)</h4>
    <ol class="help">
      <li>회사는 본 약관의 내용을 광고주 및 회원이 쉽게 알 수 있도록 회원가입 절차와 어플리케이션, 사이트 초기화면에 게시합니다.</li>
      <li>회사는 '약관의 규제에 관한 법률', '전자거래기본법', '전자서명법', '정보통신망 이용촉진 및 정보보호 등에 관한 법률' 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
      <li>회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 개정약관 적용일자 7일 전부터 적용일자까지 어플리케이션 및 사이트에 공지합니다. 다만, 개정 내용이 광고주나 회원에게 불리한 경우에는 개정약관 적용일자 30일 전부터 적용일자까지 어플리케이션 및 사이트에 공지하거나 광고주나 회원이 등록한 이메일 주소로 약관 개정 사실을 발송하여 고지합니다.</li>
      <li>광고주 및 회원이 명시적으로 개정약관의 적용에 대해 거부의 의사표시를하지 아니한 경우 개정약관에 동의한 것으로 봅니다.</li>
      <li>광고주 및 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 광고주나 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.</li>
    </ol>

    <h4 class="title-left title">제 4조 (이용계약 체결)</h4>
    <ol class="help">
      <li>이용계약은 광고주나 회원이 되고자 하는 자가 약관의 내용에 대하여 동의를 한 다음 회원가입, 광고주가입 신청을 하고 회사가 승낙함으로써 체결됩니다.</li>
      <li>광고주, 회원 가입시 신청 양식에서 요구한 정보를 작성하고 '서비스 이용약관'과 '개인정보처리방침'에 동의하는 것은 이 약관 및 '개인정보처리방침'의 내용을 숙지하고, 회사의 각종 서비스 정책과 공지사항을 준수하는 것에 대해 동의하는 것으로 봅니다.</li>
      <li>회사는 아래 각 호의 경우에 승낙을 철회하거나 거절할 수 있습니다.</li>
      <ol type="a">
        <li>가입신청자가 이 약관에 의하여 이전에 광고주나 회원자격을 상실한 사실이 있는 경우. 단, 자격 상실 후 회사의 재가입 승낙을 얻은 경우에는 예외로 합니다.</li>
        <li>등록을 신청한 사람이 만 14세 미만일 경우</li>
        <li>이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한제반 사항을 위반하며 신청하는 경우</li>
        <li>광고주나 회원 가입시 기재사항 및 기타 기재사항의 등록 내용에 허위, 기재누락, 도용, 오류 표기가 있는 경우 회사는 서비스를 이용하는 회원을 등급별로 구분하여 서비스 이용 범위에 차등을 둘 수 있습니다.</li>
      </ol>
      <li>광고주 및 회원은 불법, 편법, 서비스 운영 정책의 우회를 목적으로 복수의 회원 가입을 해서는 안됩니다.</li>
    </ol>

    <h4 class="title-left title">제 5조 (회원정보의 변경)</h4>
    <ol class="help">
      <li>광고주 및 회원은 개인정보관리화면을 통하여 본인의 개인정보를 열람하고 수정할 수 있습니다. 단, 서비스 관리를 위해 이름, 성별 등은 직접 수정이 불가능하며, 수정이 필요한 경우 회사에 소정의 절차를 거쳐 요청하는 것으로 합니다.</li>
      <li>광고주 및 회원은 회원정보로 기재한 사항이 변경되었을 경우 온라인으로수정을 하거나 전자우편, 기타 방법으로 회사에 그 변경사항을 알려야 합니다.</li>
      <li>제2항의 변경사항을 회사에 알리지 않아 발생한 불이 익에 대하여 회사는책임지지 않습니다.</li>
    </ol>

    <h4 class="title-left title">제 6조 (회원의 아이디, 비밀번호, 닉네임의 관리에 대한 의무)</h4>
    <ol class="help">
      <li>광고주나 회원의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며,이를 제3자가 이용하도록 하여서는 안 됩니다.</li>
      <li>광고주나 회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다. 그 사실을 회사에 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이 익에 대하여 회사는 책임지지 않습니다.</li>
      <li>회사는 광고주나 회원의 아이디나 닉네임이 아래 각 호에 해당되는 경우 회원의 아이디 또는 닉네임의 사용을 제한 또는 변경할 수 있습니다.</li>
      <ol type="a">
        <li>개인정보 유출이 우려되는 경우</li>
        <li>타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우</li>
        <li>회사 및 회사의 운영자로 오인할 우려가 있는 경우</li>
        <li>기타 합리적인 사유가 있는 경우</li>
      </ol>
    </ol>

    <h4 class="title-left title">제 7조 (회원에 대한 통지)</h4>
    <ol class="help">
      <li>회사는 광고주나 회원이 어플리케이션 및 사이트에 등록한 이메일, 전화번호 등의 연락처 또는 커뮤니케이션이 가능한 서비스를 통해 광고주및 회원에게 통지할 수 있습니다.</li>
      <li>불특정 다수 광고주나 회원에 대한 통지를 해야 할 경우에는 통지 사항을 어플리케이션 및 사이트 게시판 등에 게시함으로써 제 1항의 통지에 갈음할 수 있습니다.</li>
      <li>광고주나 회원이 개인정보를 허위로 기재하거나 기재사항의 변경 관리를 소홀히 하면 회사의 중요한 통지를 받지 못할 수 있으며, 그로 인한 불이익에 대해서는 보호받지 못합니다.</li>
    </ol>

    <h4 class="title-left title">제 8조 (회사의 의무)</h4>
    <ol class="help">
      <li>회사는 법령과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지 속적이고, 안정적으로 서비스를 제공하기 위해 최선을 다합니다.</li>
      <li>회사는 안정적인 서비스의 제공을 위하여, 설비에 장  애가 생기거나 손상된 때에는 부  득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구합니다.</li>
      <li>회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 광고주 및 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련 법 및 회사의 개인정보처리방침이 적용됩니다.</li>
      <li>회사는 서비스 이용과 관련하여 광고주나 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 광고주나 회원이 제기한 의견이나 불만 사항에 대해서는 게시판을 활용하거나 이메일 등을 통하여 광고주 및 회원에게 처리 과정 및 결과를 전달합니다.</li>
      <li>회사는 광고주가 광고비 납부 후 7일 이내 광고개시를 시작하지 않은 경우에 한해 광고비 결제의 철회를 요청할 경우 이를 철회 처리합니다.</li>
    </ol>

    <h4 class="title-left title">제 9조 (광고주 및 회원의 의무)</h4>
    <ol class="help">
      <li>광고주나 회원은 아래 각 호에 해당하는 행위를 하여서는 안 됩니다.
        <ol type="a">
          <li>회원정보에 허위내용을 등록하는 행위</li>
          <li>회사가 게시한 정보의 변경</li>
          <li>회사 및 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
          <li>회사 및 기타 제3자의 명예를 훼손하거나 업무를 방해하는 행위</li>
          <li>회사의 운영자, 직원, 관계자, 기타 제3자의 정보를 도용하거나 사칭하는 행위</li>
          <li>공공질서 또는 미풍양속에 위배되는 정보를 메시지, 화상, 음성 등으로 유포하는 행위</li>
          <li>회사의 동의 없이 영리를 목적으로 서비스를 이용하는 행위</li>
          <li>회사의 동의 없이 회원의 이용 권한을 타인에게 양도, 증여하거나 이를 담보로 제공하는 행위</li>
          <li>불법, 편법, 서비스 운영 정책의 우회를 목적으로 서비스를 악용하는 행위</li>
          <li>회사의 사전 승인 없이 서비스를 이용하여 영업 활동을 하는 행위</li>
          <li>기타 불법적이거나 부당한 행위</li>
        </ol>
      </li>
      <li>광고주 및 회원은 관계법, 이 약관의 규정, 운영 정책, 서비스와 관련한 공지사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안됩니다.</li>
      <li>광고주가 슈퍼멤버스 서비스의 멤버십서비스 내에 광고주의 홍보를 진행하기 위해서는 광고비를 신용카드로 선결제 하여야 하며 광고비는 광고주가 홍보중단 및 결제 정지의 요청이 있지 않는 한 한달 단위로 자동결제 합니다.</li>
      <li>광고주가 슈퍼차트 서비스를 통해 광고주의 홍보를 진행하기 위해서는 회원들에게 사전 충전한 충전금액을 포인트 형태로 지급하여야 하고 이 과정에서 회사는 광고주의 요청에 따라 회원의 섭외, 광고 제안금액의 조정 등 대행사의 역할을 합니다.</li>
      <li>광고주는 회원들의 멤버십서비스 및 슈퍼차트 서비스 이용에 불편함이 없도록 적극 협조하여야 합니다.</li>
    </ol>
    <h4 class="title-left title">제 10조 (서비스의 제공)</h4>
    <ol class="help">
      <li>회사가 제공하는 서비스의 종류는 아래 각 호로 합니다.</li>
        <ol type="a">
          <li>슈퍼멤버스 서비스</li>
          <li>슈퍼차트 서비스</li>
          <li>기타 회사가 자체 개발하는 등의 방법으로 추가적으로 회원에게 제공하는 일체의 서비스</li>
        </ol>
      <li>서비스는 연중무휴 1일 24시간 제공함을 원칙으로 합니다.</li>
      <li>회사는 업무상 또는 기술상 서비스의 제공이 불가능한 경우 혹은 정기점검 등의 유지보수가 필요할 경우 서비스를 일시적으로 중단할 수 있으며, 이 경우 광고주 및 회원에게 이메일 또는 어플리케이션, 사이트 내 게시판 등에 사전에 공지합니다. 단, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</li>
    </ol>
    <h4 class="title-left title">제 11조 (서비스의 변경)</h4>
    <ol class="help">
      <li>회사는 변경될 서비스의 내용 및 제공일자를 광고주 및 회원에게 등록된 이메일로 통지할 수 있으며 서비스를 변경하여 제공할 수 있습니다.</li>
      <li>회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 광고주나 회원에게 별도의 보상을 하지 않습니다.</li>
    </ol>
    <h4 class="title-left title">제 12조 (정보의 제공 및 광고의 게재)</h4>
    <ol class="help">
      <li>회사는 광고주나 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보 또는 소개 등에 대해서 이메일, 유무선 매체, 서신우편 등의 방법으로 광고주 또는 회원에게 제공할 수 있습니다.</li>
      <li>회사는 제공하는 서비스와 관련되는 정보 또는 광고를 서비스 화면, 홈페이지 등에 게재할 수 있으며, 광고주 및 회원은 광고 목적의 이메일을 수신한 경우, 회사에 수신거절을 할 수 있습니다.</li>
    </ol>

    <h4 class="title-left title">제 13조 (서비스의 저작권)</h4>
    <ol class="help">
      회사가 회원에게 제공하는 각종 서비스에 대한 저작권 및 지적재산권을 포함한 일체의 권리는 회사에 귀속됩니다.
    </ol>

    <h4 class="title-left title">제 14조 (게시물의 저작권)</h4>
    <ol class="help">
      <li>회원이 서비스 내에 게시한 게시물의 저작권은 해당 게시물의 저작자에게 귀속되며, 해당 게시물이 타인의 지적재산권을 침해하여 발생하는 모든 책임은 회원 본인에게 있습니다.</li>
      <li>회원이 서비스 내에 게시한 게시물은 검색결과 내지 서비스 및 관련 프로모션 등에 노출될 수 있으며, 마케팅에 활용될 수 있습니다.</li>
    </ol>

    <h4 class="title-left title">제 15조 (게시물의 관리)</h4>
    <ol class="help">
      <li>회원의 게시물이 아래 각 호에 해당하는 경우, 회사는 별도의 통보 없이 게시물의 숨김 및 삭제 등의 조치를 할 수 있습니다.</li>
        <ol type="a">
          <li>정보통신망법 및 저작권법 등 관련법 위반</li>
          <li>타인의 명예 훼손 및 권리침해</li>
          <li>공공질서 또는 미풍양속에 위배</li>
          <li>영리 및 광고 목적</li>
          <li>기타 서비스 운영 정책에 반하는 경우</li>
        </ol>
      <li>천재지변 또는 이에 준하는 불가항력, 시스템의 오류 등으로 게시물이 손실 및 삭제될 수 있습니다.</li>
      <li>게시물의 손실 및 삭제로 인한 피해에 대해서 회사는 별도의 보상을 하지 않습니다.</li>
    </ol>
    <h4 class="title-left title">제 16조 (게시물, 콘텐츠의 저작권)</h4>
    <ol class="help">
      <li>회원이 콘텐츠로 등록한 게시물의 저작권은 회원에게 있으며, 등록한 콘텐츠의 저작권 침해를 비롯한 법적 책임은 회원 본인에게 있습니다.</li>
      <li>회원은 콘텐츠에 대하여 사용료 없는 영구적인 사용권을 회사에 부여합니다. 회사는 회원이 탈퇴한 후에도 사용권을 가지며, 여기에는 콘텐츠의 복제, 전시, 배포, 편집, 2차적 저작물의 작성 권한 등이 포함됩니다. 또한 콘텐츠는 검색결과 내지 서비스 관련 프로모션 등에 노출될 수 있으며, 회사는 콘텐츠를 마케팅에 활용할 수 있습니다.</li>
      <li>회사는 콘텐츠 등록을 위해 회원에게 지원금을 지급하거나 사례를 제공할수 있으며, 별도의 계약이 없는 한 회사는 이와 관련하여 추가 보상을 하지 않습니다.</li>
      <li>회사는 회원이 콘텐츠로 등록한 게시물의 링크 또는 콘텐츠, 콘텐츠 내 멀티미디어를 각각 또는 결합하여 광고주에 제공하거나 마케팅에 활용할 수 있습니다.</li>
      <li>회사는 광고주에 제공된 콘텐츠의 취지를 벗어나지 않는 정도의 편집, 수정, 가공 등을 할 수 있으며 출처의 표기를 제외할 수도 있습니다.</li>
    </ol>
    <h4 class="title-left title">제 17조(게시물, 콘텐츠의 관리)</h4>
    <ol class="help">
      <li>회원은 멤버십서비스 이용 후 후기등록기한 이내에 게시물을 등록해 서비스별 최소 등록기한(슈퍼멤버스 3개월, 슈퍼차트 6개월) 동안 게시하여 제3자가 콘텐츠를 열람할 수 있도록 하여야 합니다.</li>
      <li>회사는 아래 각 호에 해당하는 경우, 회원에게 제공한 지원금 또는 사례에 대하여 환수 조치를 취할 수 있고 탈퇴요청을 거부할 수 있으며, 관련 법 조항(형법 제 347조)에 따라 법적 처벌 대상이 될 수 있습니다.</li>
      <ol type="a">
        <li>멤버십서비스 이용 후 특별한 사유없이 게시물을 후기등록기한 이내에 등록하지 않을 경우</li>
        <li>콘텐츠 유지기간 내에 회원이 임의로 콘텐츠를 삭제 및 숨김 등 접근 불가능하게 변경</li>
        <li>회원의 귀책사유 또는 개인 사정 등 멤버십서비스 계약 이외의 사유로 게시물 또는 콘텐츠 삭제</li>
        <li>콘텐츠 유지기간이 남아있는 후기가 있는 상태에서 탈퇴요청을 하는 경우</li>
      </ol>
      <li>회사는 아래 각 호에 해당하는 경우, 게시물의 삭제 혹은 수정을 요청 할 수 있으며, 이 경우 회원은 요청을 받은 날로부터 24시간 내에 회사의 요청에 응해야 합니다.</li>
      <ol type="a">
        <li>정보통신망법 및 저작권법 등 관련법 위반</li>
        <li>타인의 명예 훼손 및 권리침해</li>
        <li>공공질서 또는 미풍양속에 위배</li>
        <li>게시물의 내용이 멤버십서비스의 목적이나 사실에서 크게 벗어나는 경우</li>
        <li>광고주의 요청이 있는 경우</li>
        <li>기타 서비스 운영 정책에 반하는 경우</li>
      </ol>
      <li>회원이 게시물 등록 후 광고주는 게시물에 대한 평가를 진행하고 업체의 평가가 별 2개 이하인 경우 회사는 회원에게 경고를 합니다.</li>
      <li>회사는 경고가 월 2회 이상인 회원에 대해 1개월간 멤버십서비스의 이용을 일시정지하며, 일시정지가 년 2회 누적인 회원에 대해서는 서비스의 해지 및 재가입 불가 처리를 합니다.</li>
      <li>회사는 미등록 후기가 발생한 광고주에 대한 보상으로 후기 미등록 건이 해당하는 차수의 광고종료일 +30일 이후 후기 미등록율이 5% 이상일 경우 차회 광고비에 대해 30% 할인, 미등록율이 10% 이상일 경우는 차회 광고비에 대해 50% 할인을 적용하여 결제합니다. (구글리뷰는 제외됨)</li>
    </ol>
    <h4 class="title-left title">제 18조 (스폰서 배너의 관리)</h4>
    <ol class="help">
      <li>회원은 회사의 요청에 따라 콘텐츠로 등록 할 게시물에 '스폰서 배너(광고표기)'를 포함하여야 합니다.</li>
      <li>공정거래위원회의 '추천•보증 등에 관한 표시•광고 지침'에 따라 회사는 회원에게 광고주가 제공한 경제적 대가에 대한 내용을 게시물에 포함하도록 요청할 수 있습니다.</li>
      <li>회원이 임의로 '스폰서 배너'를 수정 및 삭제 할 경우, 서비스 이용제한 등의 제재를 받을 수 있으며 이로 인해 발생되는 손해 배상 및 법적 책임은 회원 본인에게 있습니다.</li>
    </ol>
    <h4 class="title-left title">제 19조 (이용계약 해지)</h4>
    <ol class="help">
      <li>광고주 및 회원은 언제든지 이용계약 해지 신청을 할 수 있으며 회사는 즉시 해지 처리합니다.</li>
      <li>광고주나 회원이 계약을 해지하는 경우 관련법 및 개인정보처리방침에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 개인정보가 소멸됩니다.</li>
      <li>회원이 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우 회사는 일방적으로 계약을 해지할 수가 있습니다.</li>
      <li>회원이 1년 동안 회사의 서비스에 로그인한 기록이 없는 경우 휴면 계정으로 전환되며 계약이 해지 됩니다.</li>
      <li>본 계약이 해지 된 경우에도 회원이 멤버십서비스에 등록한 콘텐츠는 삭제되지 않습니다.</li>
      <li>본 계약이 해지 된 경우 회원이 공용 게시판에 등록한 게시물, 댓글 등은 삭제되지 않습니다.</li>
    </ol>
    <h4 class="title-left title">제 20조 (이용제한)</h4>
    <ol class="help">
      <li>회사는 광고주나 회원이 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우 전체 또는 일부 서비스를 일시정지, 영구이용정지 등으로 서비스의 이용을 제한 할 수 있습니다.</li>
      <li>본 조에 따라 서비스 이용을 제한하는 경우에는 제 7조(회원에 대한 통지)에 따라 통지를 하고 회원은 7일 내에 소명의 기회를 부여 받습니다.</li>
      <li>광고주나 회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가 인정하는 경우 회사는 서비스의 이용을 재개 할 수 있습니다.</li>
    </ol>
    <h4 class="title-left title">제 21조 (손해배상)</h4>
    <ol class="help">
      <li>광고주나 회원은 이 약관을 위반하거나 관계 법령을 위반하여 회사에 손해가 발생한 경우에는 회사에 그 손해를 배상하여야 합니다.</li>
      <li>서비스의 이용이 제한되는 경우 서비스 이용을 통해 획득한 포인트 및 충전금액이 모두 소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다.</li>
    </ol>

    <h4 class="title-left title">제22조(광고주 광고비 환불규정)</h4>
    <ol class="help">
      <li>슈퍼멤버스 월단위 결제의 경우 광고비 납부 후 7일 이내 광고개시를 시작하지 않은 경우에 한해서만 환불이 가능하며 광고개시 이후에는 환불이 불가 합니다.</li>
      <li>슈퍼멤버스 장기결제의 경우 광고가 진행된 월에 대해 정상가로 정산 후 차액에 대해 환불이 가능합니다.</li>
      <li>슈퍼차트에서 광고주가 직접 운영하는 캠페인의 경우 환불을 요청하는 시점에 남아있는 충전금액에 대해 환불 요청이 가능합니다.</li>
      <li>슈퍼차트에서 광고대행으로 진행되는 캠페인의 경우 대행이 진행되기 전 요청하는 금액에 대해서는 전액 환불 요청이 가능합니다. 다만 회사의 업무 인력이 투입되어 대행이 이미 진행되고 있는 경우 해당 캠페인의 예산에 대해 30%의 환불 수수료를 제하고 환불 처리가 가능합니다.</li>
      <li>위 4항의 경우 회사에서 더 이상 대행이 불가하다고 판단되어 처리되는 환불 건에 대해서도 동일하게 적용됩니다.</li>
    </ol>

    <h4 class="title-left title">제23조(포인트 지급)</h4>
    <ol class="help">
      <li>슈퍼차트 서비스 이용과 관련하여 회사는 회사가 별도로 정한 수익배분 기준에 따라 포인트를 회원에게 지급합니다.</li>
      <li>회사는 아래의 경우 포인트 지급금액을 취소, 조정 혹은 보류할 수 있습니다.</li>
      <ol type="a">
        <li>광고주와 회원 간 분쟁이 발생한 경우</li>
        <li>회원이 등록한 후기가 회사가 정한 후기 가이드에 적합하지 않은 경우</li>
        <li>후기등록일 +14일 이내 등록된 후기의 트래픽이 광고제안시 측정된 트래픽보다 낮다고 판단되는 경우</li>
        <li>약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우</li>
        <li>기타 회사에서 광고주 혹은 회원에게 문제가 있다고 판단하는 경우</li>
      </ol>
    </ol>

    <h4 class="title-left title">제24조(면책사항)</h4>
    <ol class="help">
      <li>회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</li>
      <li>회사는 광고주나 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.</li>
      <li>회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.</li>
      <li>회사는 광고주나 회원 간 또는 광고주 혹은 회원과 제3자 상호 간에 서비스를 매개로 한 커뮤니티 활동, 정보 게재, 자료 전송, 거래 등 일체의 활동에 대하여 책임을 지지 않습니다.</li>
      <li>회사는 광고주가 제공한 서비스로 인해 회원에게 발생한 불만족 또는 손해에 대하여 책임을 지지 않습니다.</li>
    </ol>

    <h4 class="title-left title">제25조(준거법 및 재판관할)</h4>
    <ol class="help">
      <li>회사와 광고주 혹은 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.</li>
      <li>회사와 광고주 혹은 회원 간 발생한 분쟁에 관한 소송은 서울중앙지방법원을 관할법원으로 합니다.</li>
    </ol>

    <h4 class="title-left title">제26조(규정의 준용)</h4>
    <ol class="help">
      <li>이 약관에 명시되지 않은 사항에 대해서는 관계법령에 의하고, 법에 명시되지 않은 부분에 대하여는 상관례에 의합니다.</li>
    </ol>

    <h4 class="title-left title">제27조(타사 기능 제공)</h4>
    <ol class="help">
      <li>회사의 웹사이트(어플리케이션 포함)는 YouTube API 서비스를 사용하고 있으며, 이용자는 회사의 웹사이트(어플리케이션 포함)를 이용함으로써 아래 각호의 외부 서비스 약관 및 정책에 동의한 것으로 간주합니다.</li>
      <ol type="a">
        <li><a target="_blank" href="https://www.youtube.com/t/terms">YouTube 서비스 약관</a></li>
        <li><a target="_blank" href="https://policies.google.com/privacy">Google 개인정보 보호 및 약관</a></li>
      </ol>
    </ol>

    <h4 class="title-left title">제2장 회원의 구매</h4>
    <br>
    <h4 class="title-left title">제28조 (제2장의 적용범위)</h4>
    <ol class="help">
      본 장은 회원이 광고주의 상품을 직접 구매하는 경우에 적용합니다.
    </ol>
    <h4 class="title-left title">제29조 (서비스 이용원칙)</h4>
    <ol class="help">
      <li>회사는 통신판매중개자로서 회원과 광고주 간의 자유로운 상품의 거래를 위한 시스템을 운영, 관리, 제공함에 그치며, 서비스를 통하여 이루어지는 광고주와 회원 간의 거래에 일체 관여하지 아니하고 이 거래에 관하여 광고주 또는 회원을 대리하지 않습니다.</li>
      <li>사는 광고주에서 제공하는 상품이 회원의 개별적 특성(신체적 특성 포함)에 적합하다거나 회원의 개별적 이용목적에 부합할 것이라는 점, 회원의 구매의사 또는 광고주의 판매의사의 존부 및 진정성, 상품의 품질, 완전성, 안정성, 적법성 및 타인의 권리에 대한 비침해성, 광고주가 입력하거나 링크시킨 URL에 게시된 자료의 진실성 또는 적법성 등 일체에 대하여 어떠한 보증도 하지 않습니다.</li>
      <li>광고주가 직접 회사에 제공한 정보와 관련하여 회원이 입을 수 있는 손해 일체, 회원이 해당 상품을 사용함으로 인하여 입을 수 있는 손해 일체에 대한 책임은 해당 상품을 제공한 광고주에게 있으며, 이와 관련하여 회사는 어떠한 책임도 부담하지 않습니다.</li>
    </ol>

    <h4 class="title-left title">제30조 (구매 신청 및 책임)</h4>
    <ol class="help">
      <li>회원은 서비스 상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, 광고주는 회원의 구매신청을 위하여 다음의 각 내용을 알기 쉽게 제공하여야 합니다.</li>
      <ol type="a">
        <li>상품의 검색 및 선택</li>
        <li>성명, 주소, 전화번호, 전자우편주소, 핸드폰번호 등의 입력</li>
        <li>약관내용, 청약철회권이 제한되는 서비스, 배송료∙설치비 등의 비용부담과 관련한 내용에 대한 확인</li>
        <li>상품의 구매신청 및 이에 관한 확인</li>
        <li>결제방법의 선택</li>
      </ol>
      <li>회원은 상품을 구매하기 전에 반드시 서비스 내에 작성한 거래의 조건을 정확하게 확인하여야 합니다. 거래의 조건을 확인하지 않고 상품을 구매하여 발생한 모든 손실, 손해는 회원 본인에게 있습니다.</li>
      <li>회원은 상품 구매시 타인의 결제 수단을 임의/무단으로 사용하여서는 안됩니다. 타인의 결제수단을 임의/무단으로 사용함으로써 발생하는 회사 및 결제수단 소유자의 손실과 손해에 대한 모든 책임은 회원에게 있습니다.</li>
      <li>서비스이용대금의 결제와 관련하여 회원이 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 회원이 부담하여야 합니다.</li>
      <li>광고주는 다음 각 호의 경우 회원의 구매신청을 승낙하지 않을 수 있습니다.</li>
      <ol type="a">
        <li>구매신청 내용에 허위, 기재누락, 오기가 있는 경우</li>
        <li>미성년자가 담배, 주류 등 청소년 보호법등 관련법령에서 금지하는 상품을 구매하는 경우</li>
        <li>기타 구매신청에 승낙하는 것이 회사의 기술상 현저히 지장이 있다고 판단하는 경우</li>
      </ol>
    </ol>
    <h4 class="title-left title">제31조 (결제방법)</h4>
    <ol class="help">
      <li>회원이 서비스를 통해 유상으로 제공받는 제공품 또는 구매한 상품에 대한 대금 결제는 다음 각호의 방법에 따라 가능합니다.</li>
      <ol type="a">
        <li>직불카드, 신용카드 등의 각종 카드 결제</li>
        <li>휴대폰 결제</li>
        <li>기타 전자적 지급방법에 의한 대금 지급 등</li>
      </ol>
      <li>회원이 대금 결제와 관련하여 입력한 정보 및 그 정보와 관련하여 발생하는 책임과 불이익은 전적으로 회원이 부담합니다.</li>
    </ol>

    <h4 class="title-left title">제32조 (상품의 배송)</h4>
    <ol class="help">
      <li>회원은 광고주의 상품 배송 지연 및 사고에 의한 미수취에 대해서 회사에 손해배상 청구를 할 수 없습니다.</li>
      <li>회사는 광고주와 협의하여 상품 배송 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.</li>
      <li>배송과 관련하여 배송업체, 금융기관 등과의 사이에 분쟁이 발생한 경우에는 관련 당사자간에 해결하여야 합니다. 회사 는 이에 대하여 관여하지 않으며, 어떠한 책임도 부담하지 않습니다.</li>
      <li>광고주는 회원과 별도의 약정이 없는 이상, 회원의 구매신청이 있는 날의 다음 영업일로부터 7일 이내에 상품을 배송할 수 있도록 주문, 포장 의뢰 등 기타 필요한 조치를 취합니다. 다만, 회원이 이미 상품 대금의 전부 또는 일부를 지급한 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 필요한 조치를 취합니다.</li>
      <li>광고주는 상품에 대한 배송수단, 배송비용 부담자, 배송기간 등을 명시합니다.</li>
      <li>광고주는 국내 및 국외 배송을 운영합니다.</li>
    </ol>
     
    <h4 class="title-left title">제33조 (품절 등을 이유로 한 환불)</h4>
    <ol class="help">
    광고주는 회원이 구매 신청한 상품이 품절 등의 사유로 제공될 수 없을 때 지체 없이 그 사유를 회원에게 통지하고, 사전에 상품의 대금을 받은 경우에는 회원이 구매 신청한 상품이 품절 등의 사유로 제공될 수 없음이 확인된 날로부터 3영업일 이내에 환불하거나 환불에 필요한 조치를 취합니다.
    </ol>
    <h4 class="title-left title">제34조 (청약철회 등)</h4>
    <ol class="help">
      <li>회원은 상품을 배송받은 날로부터 7일 이내까지 청약철회(구매취소, 반품, 교환을 포함합니다. 이하 ‘청약철회 등’)를 할 수 있습니다.</li>
      <li>회원은 상품을 배송받은 경우 다음 각 호에 해당하는 경우에는 청약철회 등을 할 수 없습니다.</li>
      <ol type="a">
        <li>회원에게 책임 있는 사유로 상품 또는 택(tag)이 멸실 또는 훼손된 경우(다만, 상품의 내용 확인을 위하여 포장을 뜯은 경우는 제외)</li>
        <li>회원의 사용 또는 일부 소비로 상품의 가치가 현저히 감소한 경우</li>
        <li>시간의 경과로 재판매가 곤란할 정도로 상품의 가치가 현저히 감소한 경우</li>
        <li>같은 성능을 지닌 상품으로 복제가 가능한 경우 그 원본인 상품의 포장을 훼손한 경우</li>
        <li>그 밖에 거래의 안전을 위하여 「전자상거래 등에서의 소비자보호에 관한 법률」 시행령 제21조(청약철회 등의 제한)에서 정하는 경우</li>
      </ol>
      <li>제2항의 b항 내지 d항의 경우, 광고주는 사전에 청약철회 등이 제한된다는 사실을 상품의 포장이나 그 밖에 회원이 쉽게 알 수 있는 곳에 명확하게 표시하거나 시험사용품을 제공하는 등의 방법으로 회원의 청약철회 등 권리행사가 방해되지 않도록 조치하여야 합니다.</li>
      <li>a항 및 b항에도 불구하고, 상품의 내용이 표시∙광고의 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 상품을 공급받은 날부터 1개월 이내에 청약철회 등을 할 수 있습니다.</li>
      <li>회원은 이용한 서비스에 청약철회의 원인이 발생한 경우 수령한 상품을 임의로 사용하거나 훼손되도록 방치 하여서는 안됩니다. 청약철회한 상품의 임의 사용이나 상품 보관의 미흡으로 인한 상품훼손에 대하여는 회원이 합당한 비용을 부담하여야 합니다.</li>
      <li>회원이 상품 수령 후 교환이나 환불을 요청하면 광고주는 「전자상거래법」 등 관련 법률에 따라 반품을 받은 후 교환이나 환불 처리를 하여야 하며, 추가로 발생하는 비용은 교환이나 환불의 책임이 있는 측에서 부담합니다.</li>
    </ol>
    <h4 class="title-left title">제35조 (청약철회 등의 효과)</h4>
    <ol class="help">
      <li>회원이 전 조에 따라 청약철회 등을 한 경우, 회원은 이미 공급받은 상품을 지체 없이 광고주에게 반환하여야 하며, 회사는 광고주가 회원으로부터 상품을 반환 받았음을 회사에 통지한날로부터 3영업일 이내에 회원에게 상품의 대금을 환불합니다. 만약 회원이 상품 배송이 시작되기 이전에 전조에 따른 청약철회 등을 한 경우, 회사는 광고주가 회원의 청약철회 등의 의사표시가 광고주에게 도달하였음을 회사에 통지한 날로부터 3영업일 이내에 회원으로부터 이미 지급받은 상품의 대금을 환불합니다.</li>
      <li>전 항의 경우 회사가 회원에게 환불을 지연한 때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률」 시행령이 정하는 지연배상금 이율(연 100분의 15)을 곱하여 산정한 지연배상금을 지급합니다.</li>
      <li>회사는 전 항에 따른 환불시에 회원이 신용카드 또는 전자화폐 등의 결제수단으로 상품의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자(이하 ‘결제업자’)로 하여금 상품대금의 청구를 정지 또는 취소하도록 요청합니다. 다만, 회사가 결제업자로부터 해당 상품의 대금을 이미 받은 때에는 지체 없이 그 대금을 결제업자에게 환급하고, 그 사실을 회원에게 알려야 합니다.</li>
      <li>청약철회등의 경우 공급받은 상품의 반환에 필요한 비용은 회원이 부담합니다. 다만 상품의 내용이 표시∙광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 상품의 반환에 필요한 비용은 광고주가 부담합니다.</li>
      <li>회사 또는 광고주는 회원에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다.</li>
      <li>회원이 상품을 제공받을 때 배송비를 부담한 경우, 광고주는 회원의 청약철회 등이 있을 때 그 비용을 누가 부담하는지를 회원이 알기 쉽도록 명확하게 표시합니다.</li>
      <li>이미 상품이 일부 사용 되거나 일부 소비된 경우, 광고주는 「전자상거래 등에서의 소비자보호에 관한 법률」 제18조 제8항에 따라 회원에게 일정한 비용을 청구할 수 있습니다.</li>
      <li>상품을 반환받은 광고주는 배송비 정산, 상품 확인이 필요할 경우 환불을 보류할 수 있고, 전조 제2항에 따른 청약철회 제한 사유에 해당하는 등 정당한 사유가 있는 경우에는 환불을 거부할 수 있습니다.</li>
      <li>광고주는 회원이 반환한 상품을 수령해야 하며, 광고주의 연락 두절 등의 사유로 상품을 반환할 수 없어 회원이 직접 회사로 상품을 반환할 때에는 회사는 상당 기간을 정하여 해당 상품을 수령할 것을 광고주에게 통지하고, 광고주가 해당 기간 내에 특별한 의사를 표시하지 않으면 회사는 해당 상품을 폐기할 수 있습니다.</li>
      <li>상품에 하자 또는 사용상의 안전성에 결함이 있는 경우, 광고주는 전량 리콜(수리, 교환, 환불)하여야 하며, 리콜에 따른 모든 비용을 부담하여야 합니다.</li>
    </ol>


    <h4 class="title-left title">제3장 판매이용약관</h4>
    <br>
    <h4 class="title-left title">제36조 (제3장의 목적)</h4>
    <ol class="help">
    본 장은 (주)마야크루(이하 ‘회사’)가 운영하는 슈퍼멤버스 웹사이트, 모바일 애플리케이션 (이하 ‘서비스’)에 판매를 하는 광고주로 가입하여 서비스에서 회원에게 제공하는 전자상거래 관련 서비스와 기타 서비스를 이용하는 자 간의 권리, 의무, 책임사항 및 기타 필요한 사항을 규정하는 것을 목적으로 합니다.
    </ol>
     
    <h4 class="title-left title">제37조 (판매 수수료)</h4>
    <ol class="help">
      <li>판매 수수료는 서비스를 이용하는 데 따른 대가로 광고주가 회사에 지급해야 하는 금액을 의미하며, 회사는 구매회원으로부터 예치 받은 금액에서 판매 수수료를 공제한 금액(이하 ‘정산대금’)을 광고주에 지급합니다.</li>
      <li>판매 수수료는 판매가격에서 회사가 정한 비율 또는 회사와 광고주가 합의한 금액으로 합니다.</li>
      <li>판매 수수료와 판매 수수료율은 회사와 광고주 간의 협의 또는 회사의 내부 사정에 따라 변경될 수 있습니다.</li>
      <li>회사는 제1항의 판매수수료에 대하여 사장님사이트를 통해 광고주에 정산내역과 세금계산서를 발행하며, 광고주는 판매수수료 및 판매수수료율 등 관련 정책을 사장님사이트를 통해 확인할 수 있습니다.</li>
      <li>광고주는 주소지 또는 대금결제를 위한 통장계좌 등에 변경이 있으면 즉시 회사에 해당 내용을 통지해야 하며, 회사는 통지의 지연으로 발생한 손해에 대해 책임을 부담하지 않습니다.</li>
      <li>회사는 광고주 중 글로벌광고주에게는 세금계산서를 발행하지 않습니다.</li>
    </ol>
     
    <h4 class="title-left title">제38조 (광고주의 관리의무)</h4>
    <ol class="help">
      <li>서비스에서 상품과 용역의 판매는 광고주 등록이 완료되는 동시에 가능하며, 이를 위해서 광고주는 상품과 용역에 관한 정보를 사장님사이트를 통하여 직접 등록, 관리해야 합니다. 이때 상품과 용역의 판매가격은 표준이익, 배송비, 판매수수료 등을 고려하여 광고주 스스로 결정합니다.</li>
      <li>광고주는 재고 수량 등 수시로 바뀌는 사항에 대한 데이터를 적절히 관리해야 하며, 광고주는 데이터를 허위로 기재할 수 없습니다.</li>
      <li>광고주는 「전자상거래 등에서의 소비자보호에 관한 법률」 (이하 ‘「전자상거래법」’), 「전자금융거래법」, 「여신전문금융업법」, 「전기통신사업법」, 「식품위생법」, 「화장품법」, 「전기용품안전관리법」, 「부가가치세법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「상표법」, 「저작권법」, 「어린이제품 안전 특별법」 등 사이트에서 상품과 용역의 판매와 관련하여 관련 법령이 요구하는 사항을 준수해야 합니다.</li>
      <li>광고주는 회사의 사전 서면 승인 없이 또는 슈퍼멤버스의 상호나 로고 등을 사용할 수 없습니다.</li>
      <li>광고주는 서비스를 통하지 않고 회원에게 직접 상품을 판매하거나 이를 유도해서는 안 되며, 이를 위반하면 회사는 해당 광고주의 서비스 이용을 정지하거나 서비스 이용계약을 해지할 수 있습니다.</li>
      <li>광고주는 판매된 상품 및 용역에 대한 보증 서비스를 자신의 책임과 비용으로 시행하여야 합니다.</li>
      <li>광고주는 회원의 문의에 성실하고 정확하게 대답해야 합니다. 광고주의 불성실, 부정확한 답변으로 회원에게 손해가 발생하면 광고주가 이에 대해 책임을 부담합니다.</li>
      <li>광고주는 상품의 판매와 관련하여 특정한 인허가 자격이 요구되면 이에 대한 요건을 만족한 후 판매 및 광고를 준비해야 합니다. 인허가 자격을 갖추지 않은 상태에서 관련 상품을 판매하여 발생한 모든 민형사상의 책임은 광고주 본인에게 있습니다.</li>
      <li>광고주는 상품 노출 위치 및 판매 기간에 대한 기준은 서비스의 효율성 제고와 회원 편의 개선을 위해 회사의 내부 정책에 따라 결정됩니다.</li>
      <li>회사는 광고주의 사유로 배송 완료까지 상당 시간이 흐르거나 취소율이 일정 수준을 초과하면 경고, 신용점수 차감, 계정 정지 등 서비스 품질향상을 위하여 해당 광고주에 필요한 조치를 할 수 있습니다. 이때 회사는 구체적인 내용에 대한 정책을 합리적인 기준에 따라 정하고 미리 공지합니다.</li>
    </ol>
     
    <h4 class="title-left title">제39조 (지적 재산권)</h4>
    <ol class="help">
      <li>광고주는 상품 및 용역 등의 등록 및 판매 등과 관련하여 제3자의 상표권, 특허권, 저작권, 성명권, 초상권 등 제반 지적 재산권을 침해하지 않아야 하며, 제3자의 지적 재산권을 사용할 때에는 정당한 권리자로부터 사용 허락을 받은 후에 사용해야 합니다.</li>
      <li>광고주가 등록∙사용한 제반 정보(초상, 성명 포함)에 대하여 제3자가 지적 재산권과 관련한 권리침해를 주장하면 회사는 광고주가 제3자의 권리침해가 아님을 입증(법원의 판결 또는 검찰의 불기소처분 등)할 때까지 해당 상품과 용역에 관한 정보의 등록 및 해당 상품과 용역의 판매를 중지할 수 있습니다.</li>
      <li>광고주가 등록한 상품 및 용역에 관한 정보는 판매 장려를 위하여 회사가 제휴한 제3자(및 서비스)와 다른 회원의 블로그, 인스타그램 등의 SNS 채널에 노출될 수 있습니다.</li>
      <li>광고주가 서비스에 등록∙사용한 정보와 관련하여 제3자로부터 소송 또는 소송 이외의 방법 등으로 이의제기를 받게 되면 광고주의 비용과 노력으로 회사(및 서비스)를 면책시켜야 하며, 면책에 실패한 경우 그로 인해 회사(및 서비스)가 입은 모든 손해를 배상하여야 합니다.</li>
    </ol>
     
    <h4 class="title-left title">제40조 (상품의 배송)</h4>
    <ol class="help">
      <li>회원의 주문에 따른 결제가 완료된 순간 회사는 광고주에게 주문 정보를 사장님사이트, 이메일(E-mail), 팩스, 전화 등의 방법으로 전달하고, 광고주는 해당 주문 정보에 따라 배송을 하여야 합니다.</li>
      <li>광고주는 주문내역을 확인하고 배송 중 상품이 파손되지 않도록 적절한 포장을 한 후 배송을 증명하거나 추적할 수 있는 물류대행(택배)업체에 배송을 위탁하여야 합니다.</li>
      <li>「전자상거래법 제15조 제 1항에 따라 광고주는 회원의 구매신청이 있는 날의 다음 영업일로부터 7일 이내에 상품을 배송할 수 있도록 주문, 포장 의뢰 등 기타 필요한 조치를 취합니다. 다만, 회원이 이미 상품 대금의 전부 또는 일부를 지급한 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 필요한 조치를 취합니다. 광고주는 상품 발송 후 사장님사이트에 송장번호 등의 발송 관련 데이터를 입력하여 발송이 완료 되었음을 증명 하여야 합니다. 상품 발송 후 발송 관련 데이터 미입력으로 인해 주문 취소가 될 경우 회원에게 환불될 수 있으며, 상품 회수에 소요되는 비용은 광고주가 부담하게 됩니다.</li>
      <li>광고주가 전항의 기한 내에 필요한 조치를 취하지 않거나, 배송지 오류 등으로 회원이 상품을 정상적으로 받지 못하면 광고주는 그에 관한 모든 책임을 부담하여야 합니다.</li>
      <li>한국 광고주는 발송 후 3일 이내에 회원이 주문한 상품을 받을 수 있도록 조치해야 하며, 회원은 배송상태가 ‘배송완료’로 되어 있으나 상품을 받지 못한 경우 또는 3영업일 이상 장기간 ‘배송 중’으로 되어 있는 경우에는 상품 미도착 신고를 접수할 수 있습니다. 회원의 상품 미도착 신고가 접수되면 광고주는 이를 확인하고 필요한 조치를 하여야 합니다. 광고주가 상품 미도착 신고 접수일로부터 10일 이내에 필요한 조치를 하지 않으면, 회사는 거래를 취소하고 회원에게 환불할 수 있습니다.</li>
      <li>회사가 광고주에 구매대금 결제 확인을 통지한 후 상당 기간 제3항에 따른 필요한 조치를 취하지 않는 경우, 회사는 별도의 개별 정책으로 회원에게 자동으로 환불 처리하는 등의 조치를 할 수 있습니다. 다만, 회원이 희망하지 않는 경우는 제외하며 개별 정책은 미리 공지합니다.</li>
      <li>회사는 제3자와 업무제휴를 통해 통합택배, 해외배송 서비스 등을 시행할 수 있습니다.</li>
    </ol>
     
    <h4 class="title-left title">제41조 (판매대금의 정산)</h4>
    <ol class="help">
    <li>회사는 판매대금에서 판매수수료, 회사에 대한 미납금 및 기타 채무금을 공제한 나머지 금액(이하 ‘정산대금’)을 광고주에 송금합니다. 다만, 허위 거래가 의심되는 경우 해당 내역을 확인하기 위하여 최고 60일까지 송금을 보류할 수 있습니다. 이 경우 광고주가 물품판매에 대한 거래 사실 증빙서류를 회사에 제공한다면 회사는 이를 확인한 후 송금 처리합니다.</li>
    <li>회사는 사장님사이트를 통하여 판매금액, 추가정산금액, 공제금액, 정산금액을 광고주에 통보합니다.</li>
    <li>계좌입금을 송금방법으로 선택한 경우 광고주가 지정한 입금계좌의 예금주는 광고주와 동일인임을 원칙으로 합니다.</li>
    <li>회원이 물품수령일로부터 영업일 기준 7일 이내에 반품, 환불 등의 의사표시를 하지 않아 구매확정 결정이 지연되면 광고주는 물품도착증빙을 첨부하여 위 기간이 지났음을 입증하여 회사에 송금을 요청할 수 있으며, 이때 회사는 광고주에 본 조 제1항과 제2항에 따른 정산대금을 송금합니다.</li>
    <li>광고주는 각 택배사에서 제공하는 물품위치추적서비스를 통하여 배송하고 배송완료일(또는 서비스 이행일)로부터 7일 이내에 구매회원이 상품 미도착, 교환, 반품, 환불 등에 대해 의사를 표시하지 않으면 회사에 의해 자동으로 구매가 확정될 수 있습니다.</li>
    <li>광고주는 기타의 배송방법으로 배송한 경우 광고주의 발송완료일로부터 20일 이후 회사는 광고주의 물품발송에 대한 증빙자료를 첨부하여 송금을 요청하면 광고주에 정산대금을 송금할 수 있습니다.</li>
    <li>회사는 회사 또는 회사가 지정한 업체를 통하여 광고주와의 판매대금 정산을 진행할 수 있습니다.</li>
       </ol>
     
    <h4 class="title-left title">제42조 (정산의 보류)</h4>
    <ol class="help">
    <li>회사는 광고주의 귀책사유로 발생한 비용을 판매대금 정산 때 공제할 수 있으며, 광고주의 이용계약 종료 후에는 해당 광고주의 판매대금의 일정 비율에 해당하는 금액을 이용계약 종료일로부터 일정 기간 예치하여 이 기간에 회원으로부터 환불, 교환 등 이의제기가 있을 때 관련 비용의 지급에 사용할 수 있습니다.</li>
    <li>회사는 장기간 배송지연 건을 배송완료 건으로 간주하여 주문 절차를 종결할 수 있되, 판매대금의 정산은 향후 회원의 환불 요청에 대비하여 일정 기간 유보할 수 있습니다.</li>
    <li>광고주의 채권자가 신청한 사항에 대해 법원이 판매대금의 가압류, 압류 및 추심명령 등을 결정하면, 회사는 광고주와 채권자 간의 합의 또는 채무액의 변제 등으로 이 결정이 해제될 때까지 판매대금의 정산을 중지할 수 있습니다.</li>
    <li>본 조에 정한 것 외에도 법률의 규정이나 합리적인 사유가 있으면 회사는 해당 사항을 광고주에 통지하고 판매대금의 전부 또는 일부의 정산을 일정 기간 유보하거나 상계할 수 있습니다.</li>
    </ol>
     
    <h4 class="title-left title">제43조 (개인정보의 보호)</h4>
    <ol class="help">
    <li>광고주는 판매서비스를 이용하면서 얻은 타인의 개인정보(구매회원 등)를 본 약관에서 정한 목적 이외의 용도로 사용할 수 없으며, 이를 위반하면 관련 법령에 따라 모든 민/형사상의 법적 책임을 지고 자신의 노력과 비용으로 회사를 면책시켜야만 하며, 회사는 해당 광고주를 탈퇴시킬 수 있습니다.</li>
    <li>회사는 개인정보보호를 위하여 배송 등의 목적으로 광고주에 공개된 구매회원의 개인정보를 상당 기간이 지난 후 비공개 조치할 수 있습니다.</li>
    <li>회사가 개인정보를 보호하는 데 상당한 주의를 기울였음에도 특정 광고주가 제1항을 위반하여 타인의 개인정보를 유출하거나 유용했을 때 회사는 그에 대하여 아무런 책임을 부담하지 않습니다.</li>
    <li>전기통신사업법 등 관련 법령이 규정하는 적법한 절차에 따라 수사기관 등이 광고주에 관한 정보의 제공을 회사에 요청했을 때 회사는 해당 자료를 제출할 수 있습니다.</li>
    <li>광고주가 불법행위를 하였다면 회사는 이에 대한 수사 등을 의뢰하기 위하여 관련 자료를 수사기관 등에 제출할 수 있고, 광고주는 이에 동의합니다.</li>
    <li>광고주는 회사로부터 제공받은 개인정보(구매회원 등)를 제공받은 목적(배송, CS 등)의 용도로 법령 또는 회사가 정한 기간 동안 보유할 수 있으며 그 기간이 종료될 경우 즉시 파기해야 합니다. 또한, 제공받은 개인정보(회원 등)의 주체로부터 직접 파기 요청을 받은 경우 이에 응해야 합니다.</li>
    <li>광고주는 관련 법령 및 방송통신위원회 고시 등에 따라 회사로부터 제공받은 개인정보(회원 등)를 보호하기 위하여 기술적∙관리적 보호조치를 취해야 합니다.</li>
    <li>기타 개인정보와 관련된 내용은 회사의 「개인정보처리방침」을 따릅니다.</li>
       </ol>
     
    <h4 class="title-left title">제44조 (회사의 면책)</h4>
    <ol class="help">
    <li>회사는 서비스를 기반으로 한 거래시스템만을 제공할 뿐, 광고주가 등록한 상품과 용역, 그에 관한 정보 등에 대한 책임은 광고주에 있습니다. 또한, 광고주와 회원과의 거래에서 분쟁이 발생했을 때 회사는 그 분쟁에 개입하지 않으며 분쟁의 결과에 대한 모든 책임은 광고주가 부담합니다. 이와 관련하여 회사가 제3자에게 손해를 배상하거나 기타 비용을 지출하게 된다면 회사는 광고주에게 구상권을 행사할 수 있습니다. 단, 회사는 분쟁의 합리적이고 원활한 조정을 위하여 회사가 설치/운영하는 분쟁조정센터(고객센터 포함)를 통하여 예외적으로 해당 분쟁에 개입할 수 있으며, 광고주는 분쟁조정센터의 결정을 신의성실의 원칙에 따라 최대한 존중해야 합니다.</li>
    <li>회사는 권리자의 적법한 요구가 있으면 해당 상품과 용역 등에 관한 정보를 삭제하거나 수정할 수 있으며, 광고주는 이에 따른 손해배상을 회사에 청구할 수 없습니다.</li>
    <li>회사는 「전자상거래법」 제20조 제2항에 따라 광고주의 정보를 열람하는 방법을 회원에게 제공할 수 있으며, 광고주는 해당 정보를 기재하지 않거나 허위로 기재하여 발생하는 모든 책임을 져야 합니다.</li>
    <li>회사는 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 통신 두절 등의 사유가 발생하면 판매서비스의 제공을 일시적으로 중단할 수 있으며, 이와 관련하여 고의 또는 중대한 과실이 없는 한 책임을 부담하지 않습니다.</li>
       </ol>
     
    <h4 class="title-left title">제45조 (매매부적합상품)</h4>
    <ol class="help">
    <li>다음 각호의 매매부적합상품은 판매를 금지하며, 매매부적합상품을 판매했을 때의 모든 책임은 해당 매매부적합상품을 등록한 광고주가 부담합니다.</li>
      <ol type="a">
        <li>허위 또는 과장 광고한 상품</li>
        <li>지적 재산권, 상표권 등 타인의 권리를 침해하는 상품 </li>
        <li>「형법」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」 등 관련 법령에서 유통을 금지하는 음란물 </li>
        <li>철도승차권 등 발행자가 통신판매를 금지한 유가증권 </li>
        <li>유통검정/검사, 국가법정의무인증을 통과하지 못 하였거나 인증표시가 없는 어린이제품, 전기용품, 공산품 등 </li>
        <li>심의되지 않거나 불법 복제된 영상물, 음반, 게임물 등 </li>
        <li>장물이나 습득한 유실물 </li>
        <li>주류, 담배, 도수가 있는 안경·선글라스, 콘택트렌즈, 군수품, 의약품 등 관련 법령에 따라 통신판매가 금지되는 상품 </li>
        <li>「동물보호법」에서 정하고 있는 살아 있는 동물들(포유류, 조류, 파충류, 양서류, 어류) </li>
        <li>기타 관계 법령에 저촉되거나 회사와 광고주 사이에 체결된 서비스 이용 계약에서 판매가 금지된 상품, 또는 회사가 합리적인 사유로 판매를 금지하는 상품</li>
      </ol>
      <li>회사는 매매부적합상품이 발견되면 해당 상품의 광고를 삭제하거나 그 판매를 중지시킬 수 있으며, 해당 상품이 이미 판매 되었다면 그 거래를 취소할 수 있습니다. 이때 광고주가 취소된 거래와 관련하여 지급한 서비스 이용료는 환불되지 않습니다.</li>
      <li>회사는 매매부적합상품을 등록한 광고주의 회원 자격을 정지시키거나 탈퇴시킬 수 있으며, 매매부적합상품으로 입은 손해를 해당 광고주에 청구할 수 있습니다.</li>
      <li>광고주가 제1항의 매매부적합상품 중 위조품을 판매하여 회원에게 손해가 발생했을 때 회사는 손해에 상당하는 금액(구매대금 및 회원의 정신적 피해에 대한 보상)을 회원에게 직접 지급할 수 있습니다. 이때 회사는 회원에게 지급한 구매대금, 정신적 피해에 대한 보상금(금전적 가치로 환산할 수 있는 수단을 포함함) 및 위 절차와 관련하여 회사가 지출한 제반 경비(상품 운송비 등)를 해당 위조품을 판매한 광고주에 청구할 수 있습니다.</li>
      <li>제4항은 회사의 면책을 부인하는 것으로 해석될 수 없습니다.</li>
    </ol>

    <h4 class="title-left title">제46조 (금지행위)</h4>
    <ol class="help">
      <li>광고주는 회사 또는 서비스의 경영 또는 영업 활동을 방해하는 불공정행위를 직∙간접적으로 하거나 제3자로 하여금 하게 해서는 안 됩니다. 특히 광고주는 직접 또는 회사(또는 서비스)와 경쟁 관계에 있는 제3자의 회사(이하 ‘경쟁회사’) 또는 해당 서비스와 연계∙공조하여 「독점규제 및 공정거래에 관한 법률」 상 금지되는 불공정행위(예컨대, 사업활동방해 등) 등을 하여 회사나 서비스의 영업에 피해를 주는 행위를 해서는 안 됩니다.</li>
      <li>회사는 판매 상품 오등록 등 회사와 다른 광고주의 정상적인 영업활동을 방해하는 판매방식 행위를 금지하고 있으며 광고주에 해당 상품의 수정을 요구할 수 있고, 적발횟수에 따라 해당 상품을 판매금지 또는 해당 광고주의 서비스 이용을 제한할 수 있습니다.</li>
      <ol type="a">
        <li>카테고리 오등록이란 판매상품과 관계없는 카테고리에 상품을 등록하는 것을 말합니다.</li>
        <li>중복등록이란 같은 카테고리와 인접 카테고리에 같은 상품(같은 상품으로 간주되는 경우를 포함)을 같거나 유사한 판매조건으로 복수 등록하는 것을 말합니다. </li>
        <li>회사는 기타 비정상적인 방법으로 상품을 노출하는 모든 행위를 금지합니다.</li>
      </ol>
      <li>재고를 보유하지 않은 광고주가 허위로 재고를 등록하고 구매신청이 들어오면 재고를 보유한 다른 광고주에 재주문하여 회원의 배송지로 배송하는 행위 또는 다른 오픈마켓이나 인터넷쇼핑몰 등에 임의로 최저가를 등록하는 등으로 회사와 판매 약정한 상품을 재판매하는 행위를 금지합니다.</li>
      <li>광고주 본인의 상품 구매 또는 실제 상품의 이동 없이 판매점수 및 광고효과를 높이기 위한 구매 또는 부당한 할인 획득을 위해 제3자의 아이디를 이용한 구매 행위는 금지되며, 해당 내용이 확인된 경우 회사는 해당 거래를 취소하거나 광고주에 대한 서비스이용제한 등의 조치를 할 수 있습니다.</li>
      <li>허위로 발송 사실을 입력하거나 발송하지 않은 상품에 대한 운송장 번호를 미리 입력하는 행위는 금지되며, 해당 내용으로 인해 회원의 피해나 클레임이 발생할 경우 회사는 해당 상품을 판매중지 하거나 광고주에 대한 서비스이용 제한 등의 조치를 할 수 있습니다.</li>
      <li>광고주가 제1항, 제2항, 제3항, 제4항, 제5항을 포함하여본 약관에서 금지하는 행위를 한 경우, 회사는 광고주의 서비스 이용정지, 계약해지 등 회사의 손해를 최소화할 수 있는 조치를 할 수 있고, 광고주는 이에 대하여 이의를 제기할 수 없습니다.</li>
      <li>광고주는 커머스 최저가 판매 원칙을 준수하고, 위 원칙을 위반하여 타 커머스 서비스 등 온라인 채널에서 슈퍼멤버스 서비스에서 판매하는 가격 이하로 제품을 판매하고자 할 경우 사전에 회사와 협의를 하여야 합니다. 만약 해당 내용이 확인된 경우 회사는 광고주의 상품을 판매중지하거나 서비스이용 제한 등의 조치를 할 수 있습니다.</li>
    </ol>
     
    <h4 class="title-left title">제47조 (불공정 행위 금지)</h4>
    회사는 다음 각호의 어느 하나에 해당하는 불공정 거래 행위 또는 부당한 공동행위를 하지 않으며, 「독점규제 및 공정거래에 관한 법률」 등 관련 법령을 준수합니다.
    <ol class="help" type="a">
      <li>정상적인 거래 관행에 비추어 부당한 조건 등 불이익을 광고주에게 제시하여 거래하도록 강제하는 행위</li>
      <li>판매 수수료의 결정과 관련하여 회사와 경쟁 관계에 있는 제3의 회사와 담합하는 등의 불공정 행위</li>
      <li>회사와 경쟁 관계에 있는 제3의 회사와 거래하는 것을 금지하거나 부당하게 강요하는 행위 등 불이익을 주는 행위</li>
    </ol>
     
    <h4 class="title-left title">제48조 (비밀유지)</h4>
    <ol class="help">
      <li>각 당사자는 법령상 요구되는 경우를 제외하고는 상대방으로부터 취득한 회원정보, 기술정보, 생산 및 판매계획, 노하우 등 비밀로 관리되는 정보를 제3자에게 누설해서는 안되며, 그 정보를 이용계약의 이행 이외의 목적으로 사용해서는 안 됩니다.</li>
      <li>제1항의 의무는 회사와 광고주 사이에 체결된 서비스 이용계약의 종료 후에도 3년간 존속합니다.</li>
    </ol>
  </div>
</template>

<script>
  export default {
    name: 'Terms'
  }
</script>

<style scoped lang="scss">
  .help-contents {
    padding: 80px 0;
    font-size: 14px;
  }

  .help {
    line-height: 1.8em;
    padding-bottom: 20px;
  }

  ol.help > ul {
    list-style-type: square;
  }
</style>
